import { combineReducers } from "redux";

// reducer import
import appReducer from "./app/reducers";

//-----------------------|| COMBINE REDUCER ||-----------------------//

const reducer = combineReducers({
  app: appReducer,
});

export default reducer;

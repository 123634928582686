import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import {
  SET_RETAILER,
  SET_CATEGORY,
  SET_START_DATE,
  SET_END_DATE,
} from "../redux/app/actions";

// Material Ui
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Autocomplete,
  TextField,
  Hidden,
  InputAdornment,
} from "@material-ui/core";
import { gridSpacing, maxDate, minDate } from "../constants";
import { validateString, validateArrayData } from "../utils";

// Components Import
import CustomDatePicker from "./CustomDatePicker";
import MainCard from "./MainCard";

// assets
import CategoryIcon from "@material-ui/icons/Category";
import DateRangeIcon from "@material-ui/icons/DateRange";
import StoreIcon from "@material-ui/icons/Store";

const useStyles = makeStyles((theme) => ({
  paperClass: {
    padding: "18px !important",
    [theme.breakpoints.up("md")]: {
      padding: "16px !important",
      //   borderRadius: "3px !important",
    },
  },
}));

const GlobalFilters = ({ handleRetailerChange, history, t }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // State's
  const appState = useSelector((state) => state.app);

  const handleCatergoryChange = (val) => {
    dispatch({
      type: SET_CATEGORY,
      category: val,
    });
  };

  const handleDateRangeChange = (startDate, endDate) => {
    dispatch({
      type: SET_START_DATE,
      start_date: startDate,
    });
    dispatch({
      type: SET_END_DATE,
      end_date: endDate,
    });
  };

  return (
    <Box marginBottom={2}>
      {/* <Hidden mdUp> */}
      <MainCard content={false} className={classes.paperClass}>
        <Grid container spacing={gridSpacing}>
          {/* <Grid item lg={4} md={4} xs={12}>
            <Typography variant="h5" color="secondary" sx={{ mb: 1 }}>
              <StoreIcon fontSize="16px" color="inherit" /> {t("Retailer")}
            </Typography>
            <Autocomplete
              // disablePortal
              disableClearable={true}
              id="retailer"
              options={
                validateArrayData(appState.retailersAccessList)
                  ? appState.retailersAccessList
                  : []
              }
              value={appState.retailerSelected || null}
              onChange={(event, newValue) => {
                handleRetailerChange(newValue);
              }}
              fullWidth
              getOptionLabel={(option) => option || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  color="secondary"
                  variant="standard"
                  placeholder="Search"
                />
              )}
            />
          </Grid> */}
          <Grid item lg={4} md={4} xs={12}>
            <Typography variant="h5" color="secondary" sx={{ mb: 1 }}>
              <CategoryIcon fontSize="16px" color="inherit" /> {t("Category")}
            </Typography>
            <Autocomplete
              // disablePortal
              disableClearable={true}
              id="category"
              options={
                validateArrayData(appState.categoryAccessList)
                  ? appState.categoryAccessList
                  : []
              }
              value={appState.category || null}
              onChange={(event, newValue) => {
                handleCatergoryChange(newValue);
              }}
              fullWidth
              getOptionLabel={(option) => option || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  color="secondary"
                  size="small"
                  variant="standard"
                  placeholder="Search"
                />
              )}
            />
          </Grid>
          <Grid item lg={4} md={4} xs={12}>
            <Typography variant="h5" color="secondary" sx={{ mb: 1 }}>
              <DateRangeIcon fontSize="16px" color="inherit" />{" "}
              {t("Date Range")}
            </Typography>
            <CustomDatePicker
              minDate={minDate}
              maxDate={maxDate}
              start_date={appState.start_date}
              end_date={appState.end_date}
              handleDateRangeChange={handleDateRangeChange}
            />
          </Grid>
        </Grid>
      </MainCard>
      {/* </Hidden> */}
    </Box>
  );
};

GlobalFilters.propTypes = {};

export default withTranslation()(withRouter(GlobalFilters));

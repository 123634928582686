import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

import {
  SET_COUNTRY,
  SET_MENU,
  SET_IS_LOADING_SPIN,
  SET_RETAILER_LIST,
  SET_RETAILER,
  SET_CATEGORY_LIST,
  SET_CATEGORY,
} from "../../../../redux/app/actions";

import { RequestCategoryBrandEndpoint } from "../../../../components/AxiosEndpoints";
import { validateObjectData, validateArrayData } from "../../../../utils";

// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Divider,
  List,
  Typography,
  Autocomplete,
  TextField,
  useMediaQuery,
} from "@material-ui/core";

// style constant
const useStyles = makeStyles((theme) => ({
  menuCaption: {
    ...theme.typography.menuCaption,
  },
  subMenuCaption: {
    ...theme.typography.subMenuCaption,
  },
  menuDivider: {
    marginTop: "2px",
    marginBottom: "10px",
  },
}));

const CountryDropDown = ({ t }) => {
  const classes = useStyles();
  const appState = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const handleCountryChange = (countrySelected) => {
    dispatch({
      type: SET_COUNTRY,
      countrySelected,
    });
    dispatch({
      type: SET_IS_LOADING_SPIN,
      isLoadingSpin: true,
    });
    matchDownMd && dispatch({ type: SET_MENU, opened: false });

    let retailersAccessList =
      appState.profileData["country_retailer_access"][appState.clientSelected][
        "retailer_access"
      ][countrySelected["country"]];

    dispatch({
      type: SET_RETAILER_LIST,
      retailersAccessList: [],
    });
    dispatch({
      type: SET_RETAILER,
      retailerSelected: "",
    });
    dispatch({
      type: SET_CATEGORY_LIST,
      categoryAccessList: [],
    });
    dispatch({
      type: SET_CATEGORY,
      category: "",
    });

    RequestCategoryBrandEndpoint({
      retailer: retailersAccessList[0],
      country: countrySelected["country"],
    })
      .then((res) => {
        if (res.status === 200 && validateObjectData(res.data)) {
          dispatch({
            type: SET_RETAILER_LIST,
            retailersAccessList,
          });
          dispatch({
            type: SET_RETAILER,
            retailerSelected: retailersAccessList[0],
          });
          dispatch({
            type: SET_CATEGORY_LIST,
            categoryAccessList: res.data["category"],
          });
          dispatch({
            type: SET_CATEGORY,
            category: res.data["category"][0],
          });
          dispatch({
            type: SET_IS_LOADING_SPIN,
            isLoadingSpin: false,
          });
        } else {
          dispatch({
            type: SET_IS_LOADING_SPIN,
            isLoadingSpin: false,
          });
        }
      })
      .catch((error) => {
        console.log(appState);
        dispatch({
          type: SET_IS_LOADING_SPIN,
          isLoadingSpin: false,
        });
      });
  };

  console.log("appState", appState.subCategoryAccessList);

  return (
    <React.Fragment>
      <List
        subheader={
          <Typography
            variant="caption"
            className={classes.menuCaption}
            display="block"
            gutterBottom
          >
            {/* {t("Country")} */}
            Sub Category
          </Typography>
        }
      >
        <Autocomplete
          // disablePortal
          disableClearable={true}
          id="country"
          options={appState.subCategoryAccessList}
          value={appState.subCategory || null}
          // onChange={(event, newValue) => {
          //   console.log(newValue);
          //   handleCountryChange(newValue);
          // }}
          getOptionLabel={(option) => option}
          fullWidth
          size="small"
          renderInput={(params) => (
            <TextField {...params} color="secondary" placeholder="Search" />
          )}
        />
      </List>

      <Divider className={classes.menuDivider} />
    </React.Fragment>
  );
};

CountryDropDown.propTypes = {};

export default withTranslation()(CountryDropDown);

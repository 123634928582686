import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Avatar,
  CardContent,
  Chip,
  ClickAwayListener,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
  TextField,
  Autocomplete,
  Divider,
  Box,
  Popover,
  Button,
  Stack,
  CircularProgress,
} from "@material-ui/core";
import ListItemButton from "@material-ui/core/ListItemButton";
//import NotificationsIcon from '@material-ui/core/Notifications';

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "../../../../components/MainCard";
import Transitions from "../../../../components/Transitions";
import axios from "axios";
import Api from "../../../../components/Api";
import { SET_APP_LANGUAGE } from "../../../../redux/app/actions";
import { timeSince, validateString } from "../../../../utils";

// assets
import {
  IconLogout,
  IconSettings,
  IconBell,
  IconCoin,
  IconInfoCircle,
  IconBellRinging,
  IconAlertOctagon,
  IconAlertCircle,
} from "@tabler/icons";

import config from "../../../../config";

// style const
const useStyles = makeStyles((theme) => ({
  navContainer: {
    width: "100%",
    maxWidth: "350px",
    minWidth: "300px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
  },
  headerAvatar: {
    cursor: "pointer",
    ...theme.typography.mediumAvatar,
    margin: "8px 0 8px 8px !important",
  },
  profileChip: {
    height: "48px",
    alignItems: "center",
    borderRadius: "27px",
    transition: "all .2s ease-in-out",
    borderColor: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.light,
    '&[aria-controls="menu-list-grow"], &:hover': {
      borderColor: theme.palette.primary.main,
      background: theme.palette.primary.main + "!important",
      color: theme.palette.primary.light,
      "& svg": {
        stroke: theme.palette.primary.light,
      },
    },
  },
  alertChip: {
    height: "40px",
    alignItems: "center",
    borderRadius: "6px",
    transition: "all .2s ease-in-out",
    borderColor: theme.palette.secondary.light,
    backgroundColor: theme.palette.secondary.light,
    '&[aria-controls="menu-list-grow"], &:hover': {
      borderColor: theme.palette.secondary.main,
      background: theme.palette.secondary.main + "!important",
      color: theme.palette.secondary.light,
      "& svg": {
        stroke: theme.palette.secondary.light,
      },
    },
  },
  alertLabel: {
    lineHeight: 0,
    padding: "10px 8px",
  },
  profileLabel: {
    lineHeight: 0,
    padding: "12px",
  },
  listItem: {
    marginTop: "5px",
  },
  cardContent: {
    padding: "16px !important",
  },
  card: {
    backgroundColor: theme.palette.primary.light,
    marginBottom: "16px",
    marginTop: "16px",
  },
  searchControl: {
    width: "100%",
    paddingRight: "8px",
    paddingLeft: "16px",
    marginBottom: "16px",
    marginTop: "16px",
  },
  startAdornment: {
    fontSize: "1rem",
    color: theme.palette.grey[500],
  },
  flex: {
    display: "flex",
  },
  name: {
    marginLeft: "2px",
    fontWeight: 400,
  },
  ScrollHeight: {
    height: "100%",
    maxHeight: "calc(100vh - 250px)",
    overflowX: "hidden",
  },
  badgeWarning: {
    backgroundColor: theme.palette.warning.dark,
    color: "#fff",
  },
}));

//-----------------------|| PROFILE MENU ||-----------------------//

const ProfileSection = ({ history, t, i18n }) => {
  const classes = useStyles();
  const theme = useTheme();

  const appState = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const { profileData } = appState;

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleLogout = () => {
    const url = Api.logout;
    const token = localStorage.getItem("token");
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };
    axios
      .post(url, {}, options)
      .then((res) => {
        localStorage.removeItem("token");
        history.push("/login");
      })
      .catch((error) => {
        localStorage.removeItem("token");
        history.push("/login");
      });
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const prevOpen = React.useRef(open);

  const handleLanguageChange = (val) => {
    console.log(val);
    dispatch({
      type: SET_APP_LANGUAGE,
      appLanguage: val,
    });

    i18n.changeLanguage(val.language_code);
  };

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <React.Fragment>
      {/* <NotificationSection /> */}
      <Chip
        classes={{ label: classes.profileLabel }}
        className={classes.profileChip}
        label={
          <IconSettings
            stroke={1.5}
            size="1.5rem"
            color={theme.palette.primary.main}
          />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <CardContent className={classes.cardContent}>
                    <Grid container direction="column" spacing={0}>
                      <Grid item className={classes.flex}>
                        <Typography variant="h4">{t("Hello")},</Typography>
                        <Typography
                          component="span"
                          variant="h4"
                          className={classes.name}
                        >
                          {validateString(profileData["user_name"])
                            ? profileData["user_name"]
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle2">
                          {validateString(profileData["user_email"])
                            ? profileData["user_email"]
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                    {/* auto complete dropdown for language and divider */}
                    <Box className={classes.searchControl}>
                      <Typography variant="h5" color="secondary" sx={{ mb: 1 }}>
                        {t("Choose Language")}
                      </Typography>
                      <Autocomplete
                        disablePortal
                        disableClearable={true}
                        id="retailer"
                        options={appState.LanguageList}
                        value={appState.appLanguage || null}
                        onChange={(event, newValue) => {
                          console.log(newValue);
                          handleLanguageChange(newValue);
                        }}
                        getOptionLabel={(option) => option.language || ""}
                        fullWidth
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            color="secondary"
                            placeholder="Search"
                          />
                        )}
                      />
                    </Box>
                    <Divider />
                    <List component="nav" className={classes.navContainer}>
                      <ListItemButton
                        className={classes.listItem}
                        sx={{
                          borderRadius: config.borderRadius + "px",
                        }}
                        onClick={handleLogout}
                      >
                        <ListItemIcon>
                          <IconLogout stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="body2">
                              {t("Logout")}
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </List>
                  </CardContent>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default withTranslation()(withRouter(ProfileSection));

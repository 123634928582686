// assets
// import { IconDashboard } from "@tabler/icons";
import { MergeType } from "@material-ui/icons";

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const consolidate = {
  id: "consolidate",
  title: "Consolidated Overview",
  type: "group",
  children: [
    {
      id: "consolidate",
      title: "Consolidated Overview",
      type: "item",
      url: "/app/consolidate",
      icon: MergeType,
      breadcrumbs: false,
      target: true,
    },
  ],
};

// action - app reducer
export const SET_MENU = "SET_MENU";
export const MENU_OPEN = "MENU_OPEN";
export const SET_APP_LANGUAGE = "SET_APP_LANGUAGE";

// action - app Initialize
export const SET_IS_MAIN_LOADING = "SET_IS_MAIN_LOADING";
export const SET_IS_LOADING_SPIN = "SET_IS_LOADING_SPIN";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
// clientAccess
export const SET_CLIENT = "SET_CLIENT";
export const SET_CLIENT_LIST = "SET_CLIENT_LIST";
// feature access
export const SET_FEATURE_LIST = "SET_FEATURE_LIST";
// countryAccess
export const SET_COUNTRY = "SET_COUNTRY";
export const SET_COUNTRY_LIST = "SET_COUNTRY_LIST";
// retailerAccess
export const SET_RETAILER = "SET_RETAILER";
export const SET_RETAILER_LIST = "SET_RETAILER_LIST";
export const SET_START_DATE = "SET_START_DATE";
export const SET_END_DATE = "SET_END_DATE";
export const SET_CATEGORY = "SET_CATEGORY";
export const SET_CATEGORY_LIST = "SET_CATEGORY_LIST";

// set query

export const SET_UMG_SEARCH_QUERY = "SET_UMG_SEARCH_QUERY";
export const SET_IS_FRANCHISE = "SET_IS_FRANCHISE";
export const SET_TOPIC = "SET_TOPIC";
export const SET_TOPIC_LIST = "SET_TOPIC_LIST";
export const SET_QUESTIONS = "SET_QUESTIONS";
export const SET_IS_QUESTIONS_LOADING = "SET_IS_QUESTIONS_LOADING";

// set retailertype

export const SET_IS_RETAILER_TYPE = "SET_IS_RETAILER_TYPE";

/*
Access flow will be 
Client -> Country -> Retailer
*/

import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { BrowserView, MobileView } from "react-device-detect";
import {
  Box,
  Popover,
  Button,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import BlueprintDateRangePicker from "./BlueprintDateRangePicker";

import fontFamily from "../../config";

// Material Ui
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  cPaper: {
    "& .MuiPaper-root": {
      borderRadius: "5px !important",
      border: "1px solid #ccc",
    },
  },
  cMobilePaper: {
    "& .MuiPaper-root": {
      borderRadius: "5px !important",
      border: "1px solid #ccc",
    },
    "& .bp3-menu.bp3-daterangepicker-shortcuts": {
      minWidth: "95px",
    },
  },
  customRangePicker: {
    fontFamily,
    "& .DayPicker-Day.DayPicker-Day--selected, .bp3-menu-item.bp3-intent-primary.bp3-active":
      {
        backgroundColor: theme.palette.primary.dark,
        color: "#ffffff",
        "&:hover, &:focus": {
          backgroundColor: theme.palette.primary.dark,
        },
      },
    "& .DayPicker-Day--selected-range": {
      backgroundColor: "#dbeeff",
    },
    "& .DayPicker-Day.DayPicker-Day--isToday .bp3-datepicker-day-wrapper": {
      borderColor: theme.palette.primary.dark,
    },
  },
}));

const datepickerShortcuts = [
  {
    dateRange: [new Date(moment().startOf("week")), new Date(moment())],
    label: "This Week",
  },
  {
    dateRange: [new Date(moment().subtract(7, "days")), new Date(moment())],
    label: "Last 7days",
  },
  {
    dateRange: [
      new Date(moment().subtract(1, "weeks").startOf("week")),
      new Date(moment().subtract(1, "weeks").endOf("week")),
    ],
    label: "Last Week",
  },
  {
    dateRange: [new Date(moment().startOf("month")), new Date(moment())],
    label: "This Month",
  },
  {
    dateRange: [new Date(moment().subtract(1, "months")), new Date(moment())],
    label: "Last 30days",
  },
  {
    dateRange: [
      new Date(moment().subtract(1, "months").startOf("month")),
      new Date(moment().subtract(1, "months").endOf("month")),
    ],
    label: "Last Month",
  },
  {
    dateRange: [new Date(moment().subtract(1, "year")), new Date(moment())],
    label: "Last 1 year",
  },
  {
    dateRange: [new Date(moment().subtract(5, "year")), new Date(moment())],
    label: "Last 5 years",
  },
];

const CustomDatePicker = ({
  minDate,
  maxDate,
  start_date,
  end_date,
  handleDateRangeChange,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "Custom-daterange-picker" : undefined;

  const [dateRange, setDateRange] = useState([
    new Date(start_date),
    new Date(end_date),
  ]);

  // Show Daterange Popover
  const showDateRangePicker = (e) => {
    setAnchorEl(e.currentTarget);
  };

  //Daterange Change
  const handleRangeChange = (dateRange) => {
    setDateRange(dateRange);
  };

  const handleApplyClick = () => {
    if (dateRange[0] && dateRange[1]) {
      const startDate = moment(dateRange[0]).format("YYYY-MM-DD");
      const endDate = moment(dateRange[1]).format("YYYY-MM-DD");
      setAnchorEl(null);
      handleDateRangeChange(startDate, endDate);
    }
  };

  return (
    <>
      <TextField
        name="date"
        variant="standard"
        size="small"
        color="secondary"
        type="text"
        fullWidth
        value={`${moment(start_date).format("MMMM, DD YYYY")} - ${moment(
          end_date
        ).format("MMMM, DD YYYY")}`}
        onClick={showDateRangePicker}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CalendarTodayIcon fontSize="14px" color="inherit" />
            </InputAdornment>
          ),
        }}
      />
      <BrowserView>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          className={classes.cPaper}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <BlueprintDateRangePicker
            className={classes.customRangePicker}
            highlightCurrentDay
            onChange={handleRangeChange}
            // minDate={new Date(minDate)}
            maxDate={new Date(maxDate)}
            value={dateRange}
            shortcuts={datepickerShortcuts}
            contiguousCalendarMonths={false}
          />
          <Box textAlign="right" sx={{ padding: "10px" }}>
            <Button variant="outlined" size="small" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{ marginLeft: "5px" }}
              onClick={handleApplyClick}
            >
              Apply
            </Button>
          </Box>
        </Popover>
      </BrowserView>
      <MobileView>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          className={classes.cMobilePaper}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <BlueprintDateRangePicker
            singleMonthOnly={true}
            className={classes.customRangePicker}
            highlightCurrentDay
            shortcuts={false}
            // minDate={new Date(minDate)}
            contiguousCalendarMonths={false}
            maxDate={new Date(maxDate)}
            onChange={handleRangeChange}
            value={dateRange}
          />
          <Box textAlign="right" sx={{ padding: "10px" }}>
            <Button variant="outlined" size="small" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{ marginLeft: "5px" }}
              onClick={handleApplyClick}
            >
              Apply
            </Button>
          </Box>
        </Popover>
      </MobileView>
    </>
  );
};

CustomDatePicker.propTypes = {
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  start_date: PropTypes.string,
  end_date: PropTypes.string,
  handleDateRangeChange: PropTypes.func,
};

CustomDatePicker.defaultProps = {
  minDate: moment().subtract(3, "months").format("YYYY-MM-DD"),
  maxDate: moment().format("YYYY-MM-DD"),
  start_date: "2021-08-01",
  end_date: "2021-09-21",
};

export default CustomDatePicker;

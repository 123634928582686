// assets
// import { IconDashboard } from "@tabler/icons";
import { Home } from "@material-ui/icons";

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const dashboard = {
  id: "dashboard",
  title: "Home",
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "Home",
      type: "item",
      url: "/app/dashboard",
      icon: Home,
      breadcrumbs: false,
    },
  ],
};

import React, { lazy } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

// project imports
import Initialize from "../views/Initialize";
import Loadable from "../components/Loadable";
import PrivateRoute from "../components/PrivateRoute";
import UMGLayout from "../layouts/UMGLayout";

// dashboard routing
const UMGDashboardPage = Loadable(
  lazy(() => import("../views/UMGDashboardPage/umg-dashboard.page"))
);

const MarketShare = Loadable(
  lazy(() => import("../views/MarketShare/marketshare.page"))
);

const WhiteSpace = Loadable(
  lazy(() => import("../views/WhiteSpace/whitespace.page"))
);

const ProductDetailPage = Loadable(
  lazy(() => import("../views/ProductDetailPage/product-detail.page"))
);

const MainSearchPage = Loadable(
  lazy(() => import("../views/MainSearchPage/main-search.page"))
);
//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
  const location = useLocation();

  return (
    <Route
      path={[
        "/app/search",
        "/app/dashboard",
        "/app/whitespace",
        "/app/marketshare",
        "/app/dashboard/detail",
      ]}
    >
      <Initialize LayoutWrapperComponent={UMGLayout}>
        <Switch location={location} key={location.pathname}>
          <PrivateRoute path="/app/search" component={MainSearchPage} />
          <PrivateRoute
            path="/app/dashboard/detail"
            component={ProductDetailPage}
          />
          <PrivateRoute path="/app/dashboard" component={UMGDashboardPage} />
          <PrivateRoute path="/app/marketshare" component={MarketShare} />
          <PrivateRoute path="/app/whitespace" component={WhiteSpace} />
        </Switch>
      </Initialize>
    </Route>
  );
};

export default MainRoutes;

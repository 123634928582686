import React, { lazy } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import Loadable from "../components/Loadable";

// project imports
import MinimalLayout from "../layouts/MinimalLayout";

// login option 3 routing
const Login = Loadable(lazy(() => import("../views/auth/Login")));
const Register = Loadable(lazy(() => import("../views/auth/Register")));
const ForgotPassword = Loadable(
  lazy(() => import("../views/auth/ForgotPassword"))
);
const ResetPassword = Loadable(
  lazy(() => import("../views/auth/ResetPassword"))
);
// project imports
// import Login from "../views/auth/Login";
// import Register from "../views/auth/Register";
// import ForgotPassword from "../views/auth/ForgotPassword";
// import ResetPassword from "../views/auth/ResetPassword";

//-----------------------|| AUTHENTICATION ROUTING ||-----------------------//

const AuthenticationRoutes = () => {
  const location = useLocation();

  return (
    <Route
      path={[
        "/login",
        "/register",
        "/forgot-password",
        "/reset-password/:uidb64/:token",
      ]}
    >
      <MinimalLayout>
        <Switch location={location} key={location.pathname}>
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route
            path="/reset-password/:uidb64/:token"
            component={ResetPassword}
          />
        </Switch>
      </MinimalLayout>
    </Route>
  );
};

export default AuthenticationRoutes;

import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";

// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Avatar, 
  Chip, 
  Grid,
  ListItem, 
  ListItemText, 
  Typography,
  Divider,
  Box,
  Popover, 
  Stack,
  CircularProgress
} from "@material-ui/core";

// project imports 
import { timeSince } from "../../../../utils"; 

// assets
import { IconCoin, IconTrendingUp, IconShoppingCartX } from "@tabler/icons";

const useStyles = makeStyles((theme) => ({
  alertChip: {
    height: "40px",
    alignItems: "center",
    borderRadius: "6px",
    transition: "all .2s ease-in-out",
    borderColor: theme.palette.secondary.light,
    backgroundColor: theme.palette.secondary.light,
    '&[aria-controls="menu-list-grow"], &:hover': {
      borderColor: theme.palette.secondary.main,
      background: theme.palette.secondary.main + "!important",
      color: theme.palette.secondary.light,
      "& svg": {
        stroke: theme.palette.secondary.light,
      },
    },
  },
  alertLabel: {
    lineHeight:0,
    padding:"10px 8px"
  }, 
  listItem: {
    marginTop: "5px",
  },
  alertTypeBadge: {
    fontSize: "12px",
    height:"20px",
    "& .MuiChip-label":{
      paddingLeft:"10px",
      paddingRight:"10px"
    },
    "&.MuiChip-filledPrimary":{
      backgroundColor:"#cee5f7",
      color: "#0077d6"
    },
    "&.MuiChip-filledSecondary":{
      backgroundColor:"#ebe0ff",
      color: "#673ab7"
    },
    "&.MuiChip-filledSuccess":{
      backgroundColor:theme.palette.success.light,
      color: "#057935"
    }
  },
  alertTypeIcon: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark
  },
  noLine: {
    textDecoration: 'none',
    color: "#212121",
    "&:hover": {
      color: "#444444"
    }
  }
}));

const NotificationList = ({data}) => {
  const classes = useStyles(); 
  const appState = useSelector((state) => state.app);
  const { clientSelected } = appState;

  //const [isWrap, setIsWrap] = useState(false);

  // const handleTypoClick = () => {
  //   setIsWrap(!isWrap);
  // }

  return (
      <>
        <ListItem alignItems="flex-start">
            <Avatar className={classes.alertTypeIcon} sx={{marginRight:"15px", marginTop:"8px"}}>
            {(data.alert_type === 'No Stock' || data.alert_type === 'Low Stock')  ? (
            <IconShoppingCartX
                size="1.3rem" 
            />
            ) : data.alert_type === 'Price Change' ? (
            <IconCoin 
                size="1.3rem" 
            />
            ): (
            <IconTrendingUp 
                size="1.3rem" 
            />
            )}
            </Avatar>
            <ListItemText>
            <Grid
                container
                alignItems="center"
                spacing={1}
                sx={{marginBottom:"10px"}}
                >
                <Grid item xs={6} sx={{fontWeight:"600", fontSize:"14px"}}>{data.alert_type}</Grid>
                <Grid item xs={6} textAlign="right"><Typography sx={{fontSize:"14px", color:"#928b8b"}}>{timeSince(new Date(data.date_time))} ago</Typography></Grid>
            </Grid>
            <Typography sx={{marginBottom:"20px",lineHeight:"20px", paddingRight:"35px", cursor:"Pointer"}}>
              {data.alert_type === "Price Change" ? (
                <Link className={classes.noLine} to="/app/price-tracking">{data.product_title}</Link>
              ) : clientSelected === 'Pepsi' && (data.alert_type === 'No Stock' || data.alert_type === 'Low Stock') ? (
                <Link className={classes.noLine} to="/app/stockout">{data.product_title}</Link>
              ) : data.product_title}
              </Typography>
            <Stack direction="row" spacing={1}>
                <Chip label={data.retailer_name} color="secondary" className={classes.alertTypeBadge} />
                <Chip label={data.category_name} color="primary" className={classes.alertTypeBadge} />
                {/* <Chip label={data.brand_name} color="success" className={classes.alertTypeBadge} /> */}
            </Stack>
            </ListItemText>
        </ListItem>
        <Divider sx={{borderColor:"#e6e6e6"}} component="li" />
    </>
  );
};

NotificationList.propTypes = { 
  data: PropTypes.object
}

export default withTranslation()(withRouter(NotificationList));
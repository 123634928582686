import { dashboard } from "./dashboard";
import { analyze } from "./analyze";
import { consolidate } from "./consolidate";
//-----------------------|| MENU ITEMS ||-----------------------//

export const menuItem = (featuresAccessList, clientSelected) => {
  let items = [dashboard, analyze()];

  return {
    items,
  };
};

import React from "react";
import PropTypes from "prop-types";
// Blue Print Core & Datetime Styles
import "./blueprint.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import { DateRangePicker } from "@blueprintjs/datetime";

const BlueprintDateRangePicker = (props) => {
  return <DateRangePicker {...props} />;
};

BlueprintDateRangePicker.propTypes = {};

export default BlueprintDateRangePicker;

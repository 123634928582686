// assets
import {
  IconCoin,
  IconAd,
  IconBuildingWarehouse,
  IconBusinessplan,
  IconSpeakerphone,
  IconListSearch,
} from "@tabler/icons";

import PivotTableChartOutlinedIcon from "@material-ui/icons/PivotTableChartOutlined";
import { TrendingUp } from "@material-ui/icons";

//-----------------------|| ANALYZE MENU ITEMS ||-----------------------//

const analyzeFeatureList = [
  {
    id: "marketshare",
    featureId: "market_share",
    title: "MarketShare",
    type: "item",
    url: "/app/marketshare",
    icon: IconBusinessplan,
  },
  // {
  //   id: "growth",
  //   featureId: "growth",
  //   title: "Growth",
  //   type: "item",
  //   url: "/app/growth",
  //   icon: TrendingUp,
  // },
  {
    id: "whitespace",
    featureId: "whitespace",
    title: "WhiteSpace",
    type: "item",
    url: "/app/whitespace",
    icon: PivotTableChartOutlinedIcon,
  },
  // {
  //   id: "price-tracking",
  //   featureId: "price_tracking",
  //   title: "Price",
  //   type: "item",
  //   url: "/app/price-tracking",
  //   icon: IconCoin,
  // },
  // {
  //   id: "advertisement",
  //   featureId: "advertisement",
  //   title: "Advertisement",
  //   type: "item",
  //   url: "/app/advertisement",
  //   icon: IconAd,
  // },
  // {
  //   id: "stockout",
  //   featureId: "stock_out",
  //   title: "Stockout",
  //   type: "item",
  //   url: "/app/stockout",
  //   icon: IconBuildingWarehouse,
  // },
  // {
  //   id: "search-insights",
  //   featureId: "search_insights",
  //   title: "Search Insights",
  //   type: "item",
  //   url: "/app/search-insights",
  //   icon: IconListSearch,
  // },
  // {
  //   id: "review-analysis",
  //   featureId: "review_analysis",
  //   title: "Reviews",
  //   type: "item",
  //   url: "/app/review-analysis",
  //   icon: IconSpeakerphone,
  // },
];

export const analyze = (featuresAccessList) => {
  return {
    id: "analyze",
    title: "Analytics",
    type: "group",
    children: analyzeFeatureList,
  };
};

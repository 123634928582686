import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import config from "../../../../config";

import { withRouter } from "react-router";

import {
  SET_IS_FRANCHISE,
  SET_IS_LOADING_SPIN,
} from "../../../../redux/app/actions";

// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Divider,
  List,
  Typography,
  Autocomplete,
  TextField,
  useMediaQuery,
} from "@material-ui/core";

// style constant
const useStyles = makeStyles((theme) => ({
  menuCaption: {
    ...theme.typography.menuCaption,
  },
  subMenuCaption: {
    ...theme.typography.subMenuCaption,
  },
  menuDivider: {
    marginTop: "2px",
    marginBottom: "10px",
  },
}));

const FranchiseDropdown = ({ history }) => {
  const classes = useStyles();
  const appState = useSelector((state) => state.app);
  const dispatch = useDispatch();

  return (
    <>
      <List
        subheader={
          <Typography
            variant="caption"
            className={classes.menuCaption}
            display="block"
            gutterBottom
          >
            Select Type
          </Typography>
        }
      >
        <Autocomplete
          // disablePortal
          disableClearable={true}
          id="franchise"
          options={appState.franchiseOptions}
          value={appState.isFranchise || null}
          onChange={(event, newValue) => {
            dispatch({
              type: SET_IS_FRANCHISE,
              isFranchise: newValue,
            });
          }}
          getOptionLabel={(option) => option.label}
          fullWidth
          size="small"
          renderInput={(params) => (
            <TextField {...params} color="secondary" placeholder="Search" />
          )}
        />
      </List>

      <Divider className={classes.menuDivider} />
    </>
  );
};

FranchiseDropdown.propTypes = {};

export default withRouter(FranchiseDropdown);

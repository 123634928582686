import moment from "moment";
import Questions from "../mock-adapter/UMGMockData/QuestionsMockData.json";
import ZafulQuestions from "../mock-adapter/UMGMockData/zafulQuestionsData.json";
import FarfetchQuestions from "../mock-adapter/UMGMockData/farfetchQuestionsData.json";

// theme constant
export const gridSpacing = 3;
export const drawerWidth = 230;
export const appDrawerWidth = 320;
export const minDate = "2021-08-01";
export const maxDate = moment().format("YYYY-MM-DD");
export const chartsColors = ["#7d2a81", "#d2212f", "#efb31e", "#7EFA0B"];

export const defaultQuestions = Questions;
export const defaultZafulQuestions = ZafulQuestions;
export const defaultFarfetchQuestions = FarfetchQuestions;

export const retailerType = ["Non apparel", "Apparel"];

const apparelRetailers = ["FARFETCH", "ZAFUL", "REVOLVE", "ASOS"];
const nonApparelRetailers = ["AMAZON"];

export const retailerOptions = {
  Apparel: apparelRetailers,
  "Non apparel": nonApparelRetailers,
};
